import classNames from 'classnames'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { Component } from 'react'
import { BsCart2, BsInboxes, BsShop } from 'react-icons/bs'
import { FaRegHandshake } from 'react-icons/fa'
import { MdOutlinePrecisionManufacturing } from 'react-icons/md'
import { VscCircleFilled, VscCloud, VscHistory, VscSettingsGear } from 'react-icons/vsc'
import { connect } from 'react-redux'
// import { logout } from '../../helpers/auth'
import CommonHelper from '../../helpers/CommonHelper'
import { setCertificadoValidoIte, setCertificadoValidoMontadora, setCertificadoValidoVendaVeiculos, setEmpresaLogada, setMensagemGlobal, setUsuarioLogado } from '../../redux/actions'
import { dispatch, getStore } from '../../redux/store'
import EmpresaService from '../../services/cadastro/EmpresaService'
import UsuarioService from '../../services/cadastro/UsuarioService'
import ConfigService from '../../services/config/ConfigService'
import MensagemGlobalService from '../../services/suporte/MensagemGlobalService'
import Message from '../utils/Message'
import Alerta from './Alerta'
import { AppMenu } from './AppMenu'
import { AppProfile } from './AppProfile'
import { AppTopbar } from './AppTopbar'

class AppContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      loading: true
    }

    this.onWrapperClick = this.onWrapperClick.bind(this)
    this.onToggleMenu = this.onToggleMenu.bind(this)
    this.onSidebarClick = this.onSidebarClick.bind(this)
    this.onMenuItemClick = this.onMenuItemClick.bind(this)
  }

  async componentDidMount () {
    this.loadInitialState().then(() => {
      this.setState({ loading: false })
    })
  }

  async loadInitialState () {
    await EmpresaService.getEmpresaLogada().then(async empresa => {
      try {
        await ConfigService.createConfigEmpAndConfigGru(empresa.codigo_emp)
      } catch (error) {}
      dispatch(setEmpresaLogada(empresa))
    }).catch(() =>
      console.log()
      // logout()
    )

    const renaveZeroKm = getStore().empresaLogada.rennov_emp
    const renaveUsados = getStore().empresaLogada.renusa_emp
    const renaveIte = getStore().empresaLogada.renite_emp
    const renaveMontadora = getStore().empresaLogada.renmon_emp

    if (renaveUsados || renaveZeroKm) {
      try {
        await EmpresaService.verificaCertificadoComSerpro('vendaVeiculos')
        dispatch(setCertificadoValidoVendaVeiculos(true))
      } catch (error) {
        dispatch(setCertificadoValidoVendaVeiculos(false))
      }
    }
    if (renaveIte) {
      try {
        await EmpresaService.verificaCertificadoComSerpro('ite')
        dispatch(setCertificadoValidoIte(true))
      } catch (error) {
        dispatch(setCertificadoValidoIte(false))
      }
    }
    if (renaveMontadora) {
      try {
        await EmpresaService.verificaCertificadoComSerpro('montadora')
        dispatch(setCertificadoValidoMontadora(true))
      } catch (error) {
        dispatch(setCertificadoValidoMontadora(false))
      }
    }
    const mensagemGlobalService = new MensagemGlobalService()

    await UsuarioService.getUsuarioLogado().then(usuario => {
      dispatch(setUsuarioLogado(usuario))
    })

    await mensagemGlobalService.get(1).then(mensagemGlobal => {
      dispatch(setMensagemGlobal(mensagemGlobal))
    })

    this.createMenu()
  }

  onWrapperClick () {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }

    this.menuClick = false
  }

  onToggleMenu (event) {
    this.menuClick = true
    if (this.isDesktop()) {
      this.setState({
        staticMenuInactive: !this.state.staticMenuInactive
      })
    } else {
      const mobileMenuActive = this.state.mobileMenuActive
      this.setState({
        mobileMenuActive: !mobileMenuActive
      })
    }
    event.preventDefault()
  }

  onSidebarClick (event) {
    this.menuClick = true
  }

  onMenuItemClick (event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }
  }

  createMenu () {
    const isUsuDesp = CommonHelper.isUsuarioDespachante()
    const isEmpDesp = CommonHelper.isEmpresaDespachante()
    const isUsuADM = CommonHelper.isUsuarioAdmin()
    const permissaoNovosDesp = getStore().empresaLogada.pernovdesp_emp
    const permissaoUsadosDesp = getStore().empresaLogada.perusadesp_emp
    const renaveZeroKm = getStore().empresaLogada.rennov_emp
    const renaveUsados = getStore().empresaLogada.renusa_emp
    const renaveIte = getStore().empresaLogada.renite_emp
    const renaveMontadora = getStore().empresaLogada.renmon_emp
    const temDespachante = getStore().empresaLogada.coddesp_emp > 0
    const mostrarIntegracao = (!isUsuDesp && temDespachante) || isUsuDesp && !temDespachante || isEmpDesp
    this.menu = [{
      label: 'Home',
      icon: 'pi pi-fw pi-home',
      to: '/home'
    }]

    if (!isEmpDesp) {
      if ((temDespachante && isUsuDesp) || (temDespachante && (!permissaoNovosDesp || !permissaoUsadosDesp)) || !temDespachante || isUsuADM) {
        this.menu.push({
          label: "Importar XML's",
          icon: 'pi pi-fw',
          reactIcon: VscCircleFilled,
          to: '/importar-xmls'

        })
      }
    }

    // É renave montadora
    if (renaveMontadora && !isEmpDesp) {
      if ((temDespachante && isUsuDesp && permissaoNovosDesp) || !temDespachante || (temDespachante && !permissaoNovosDesp && !isUsuDesp) || isUsuADM) {
        this.menu.push({
          label: 'Montadora',
          icon: 'pi pi-fw',
          reactIcon: MdOutlinePrecisionManufacturing,
          items: [
            {
              label: 'Confirmar entrada',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/confirmar-entrada-montadora',
              renaveMontadora: true
            },
            {
              label: 'Confirmar saída',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/confirmar-saida-montadora',
              renaveMontadora: true
            },
            {
              label: 'Cancelar entrada',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/cancelar-entrada-montadora',
              renaveMontadora: true
            },
            {
              label: 'Cancelar saída',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/cancelar-saida-montadora',
              renaveMontadora: true
            },
            {
              label: 'Entrega de veículos',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/entrega-de-veiculos-montadora',
              renaveMontadora: true
            },
            {
              label: 'Transferir para ITE',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/transferencia-para-ITE-na-montadora',
              renaveMontadora: true
            },
            {
              label: 'Receber da ITE',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/receber-transferencia-ITE-na-montadora',
              renaveMontadora: true
            },
            {
              label: 'ATPV-e / Termo de Saída',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/atpv-e/montadora',
              renaveMontadora: true
            }
          ]
        })
      }
    }
    if (renaveZeroKm && !isEmpDesp) {
      if ((temDespachante && isUsuDesp && permissaoNovosDesp) || !temDespachante || (temDespachante && !permissaoNovosDesp && !isUsuDesp) || isUsuADM) {
        this.menu.push({
          label: 'Veículo zero KM',
          icon: 'pi pi-fw',
          reactIcon: BsCart2,
          items: [
            {
              label: 'Confirmar entrada',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/confirmar-entrada-zero',
              renaveVendaVeiculos: true
            },
            {
              label: 'Confirmar saída',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/confirmar-saida-zero',
              renaveVendaVeiculos: true
            },
            {
              label: 'ATPV-e / Termo de Saída',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/atpv-e',
              renaveVendaVeiculos: true
            },
            {
              label: 'Cancelar entrada',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/cancelar-entrada-zero',
              renaveVendaVeiculos: true
            },
            {
              label: 'Cancelar saída',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/cancelar-saida-zero',
              renaveVendaVeiculos: true
            },
            {
              label: 'Transferir para',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              items: [
                {
                  label: 'Estabelecimento',
                  icon: 'pi pi-fw',
                  reactIcon: VscCircleFilled,
                  to: '/transferencia-entre-estabelecimentos',
                  subItem: true,
                  renaveVendaVeiculos: true
                },
                {
                  label: 'Filial',
                  icon: 'pi pi-fw',
                  reactIcon: VscCircleFilled,
                  to: '/transferencia-entre-filiais',
                  subItem: true,
                  renaveVendaVeiculos: true
                },
                {
                  label: 'ITE',
                  icon: 'pi pi-fw',
                  reactIcon: VscCircleFilled,
                  to: '/transferencia-para-ITE',
                  subItem: true,
                  renaveVendaVeiculos: true
                }
              ]
            },
            {
              label: 'Receber transferência',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              items: [
                {
                  label: 'Estabelecimento',
                  icon: 'pi pi-fw',
                  reactIcon: VscCircleFilled,
                  to: '/receber-transferencia-estabelecimentos',
                  subItem: true,
                  renaveVendaVeiculos: true
                },
                {
                  label: 'ITE',
                  icon: 'pi pi-fw',
                  reactIcon: VscCircleFilled,
                  to: '/receber-transferencia-ITE',
                  subItem: true,
                  renaveVendaVeiculos: true
                }
              ]
            },
            {
              label: 'Entrega de veículos',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/entrega-de-veiculos-concessionaria',
              renaveVendaVeiculos: true
            }
          ]
        })
      }
    }
    if (renaveUsados && !isEmpDesp) {
      if ((temDespachante && isUsuDesp && permissaoUsadosDesp) || !temDespachante || (temDespachante && !permissaoUsadosDesp && !isUsuDesp) || isUsuADM) {
        this.menu.push({
          label: 'Veículos usados',
          icon: 'pi pi-fw',
          reactIcon: BsCart2,
          items: [
            {
              label: 'Aptidão',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/aptidao-usados',
              renaveVendaVeiculos: true
            },
            {
              label: 'Estoque de veículos',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/estoque-veiculos-usados',
              renaveVendaVeiculos: true
            },
            {
              label: 'Receber transferência',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/receber-transferencia-usados-estabelecimentos',
              renaveVendaVeiculos: true
            }
          ]
        })
      }
    }
    if (renaveIte && !isEmpDesp) {
      if ((temDespachante && isUsuDesp && permissaoNovosDesp) || !temDespachante || (temDespachante && !permissaoNovosDesp && !isUsuDesp) || isUsuADM) {
        this.menu.push({
          label: 'ITE',
          icon: 'pi pi-fw',
          reactIcon: BsShop,
          items: [
            {
              label: 'Estoque',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/estoque-ite',
              renaveIte: true
            },
            {
              label: 'Transferir veículo',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/transferencia-na-ITE',
              renaveIte: true
            },
            {
              label: 'Receber transferência',
              icon: 'pi pi-fw',
              reactIcon: VscCircleFilled,
              to: '/receber-transferencia-na-ITE',
              renaveIte: true
            }
          ]
        })
      }
    }
    this.menu.push({
      label: 'Histórico movimentação',
      icon: 'pi pi-fw',
      reactIcon: VscHistory,
      to: '/historico-movimentacao'
    })

    const itemsCadastros = []

    if (!isEmpDesp) {
      if ((temDespachante && isUsuDesp) || !temDespachante || isUsuADM) {
        itemsCadastros.push({
          label: 'Pessoa',
          icon: 'pi pi-fw',
          reactIcon: VscCircleFilled,
          to: '/pessoas'
        },
        {
          label: 'Veículo',
          icon: 'pi pi-fw',
          reactIcon: VscCircleFilled,
          to: '/veiculos'
        })
      }
    }

    if (!isUsuDesp) {
      itemsCadastros.push({
        label: 'Empresa',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/empresas'
      },
      {
        label: 'Usuário',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/usuarios'
      })
    }
    if (isEmpDesp) {
      itemsCadastros.push({
        label: 'Empresa',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: `/empresa/${getStore().empresaLogada.codigo_emp}`
      },
      {
        label: 'Usuário',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/usuarios'
      }
      )
    }
    this.menu.push({
      label: 'Cadastros',
      icon: 'pi pi-fw',
      reactIcon: BsInboxes,
      items: itemsCadastros
    })

    if (!isEmpDesp) {
      this.menu.push({
        label: 'Configurações',
        icon: 'pi pi-fw',
        reactIcon: VscSettingsGear,
        to: '/configuracoes'
      })
    }
    if (mostrarIntegracao) {
      this.menu.push({
        label: 'Integração',
        icon: 'pi pi-fw',
        reactIcon: VscCloud,
        items: [
          {
            label: 'Meu token',
            icon: 'pi pi-fw',
            reactIcon: VscCircleFilled,
            to: '/meu-token'
          }
        ]
      })
    }
    // if (isEmpDesp) {
    //   this.menu.push({
    //     label: 'Integração',
    //     icon: 'pi pi-fw',
    //     reactIcon: VscCloud,
    //     items: [
    //       {
    //         label: 'Meu token',
    //         icon: 'pi pi-fw',
    //         reactIcon: VscCircleFilled,
    //         to: '/meu-token'
    //       }
    //     ]
    //   })
    // }

    if (isUsuADM) {
      this.menu.push({
        label: 'Suporte',
        icon: 'pi pi-fw',
        reactIcon: FaRegHandshake,
        items: [
          {
            label: 'Configuração módulos',
            icon: 'pi pi-fw',
            reactIcon: VscCircleFilled,
            to: '/configuracao-modulos'
          },
          {
            label: 'Mensagem global',
            icon: 'pi pi-fw',
            reactIcon: VscCircleFilled,
            to: '/mensagem-global/1'
          },
          {
            label: 'Estoque renave',
            icon: 'pi pi-fw',
            reactIcon: VscCircleFilled,
            to: '/estoque-renave'
          },
          {
            label: 'Consultar chassi',
            icon: 'pi pi-fw',
            reactIcon: VscCircleFilled,
            to: '/consultar-chassi'
          },
          {
            label: 'Registro',
            icon: 'pi pi-fw',
            reactIcon: VscCircleFilled,
            to: '/registro'
          }
        ]
      })
    }
  }

  addClass (element, className) {
    if (element.classList)
      element.classList.add(className)
    else
      element.className += ' ' + className
  }

  removeClass (element, className) {
    if (element.classList)
      element.classList.remove(className)
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  }

  isDesktop () {
    return window.innerWidth > 1024
  }

  componentDidUpdate () {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden')
    else
      this.removeClass(document.body, 'body-overflow-hidden')
  }

  render () {
    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive
    })

    const empresa = getStore().empresaLogada
    const usuario = getStore().usuarioLogado
    const mensagemGlobal = getStore().mensagemGlobal
    const certificadoValidoVendaVeiculos = getStore().certificadoValidoVendaVeiculos
    const certificadoValidoIte = getStore().certificadoValidoIte
    const certificadoValidoMontadora = getStore().certificadoValidoMontadora

    return (
      <>
        {!(empresa.codigo_emp && usuario.codigo_usu) && (
          <div className="loading-container">
            <ProgressSpinner />
          </div>
        )}
        {(empresa.codigo_emp && usuario.codigo_usu) && (
          <div className={wrapperClass} onClick={this.onWrapperClick}>
            <AppTopbar onToggleMenu={this.onToggleMenu} {...this.props}/>

            <div className="layout-sidebar layout-sidebar-light" onClick={this.onSidebarClick}>
              <div className="layout-logo">
                <object data="/assets/layout/images/Logo_RenaveAuto_com_texto.svg"></object>
              </div>
              <AppProfile {...this.props} />
              <AppMenu
                {...this.props}
                model={this.menu}
                onMenuItemClick={this.onMenuItemClick}
                certificadoValidoVendaVeiculos={certificadoValidoVendaVeiculos}
                certificadoValidoIte={certificadoValidoIte}
                certificadoValidoMontadora={certificadoValidoMontadora}
              />
            </div>
            <div className="layout-main">
              <Alerta empresa={empresa} usuario={usuario} mensagemGlobal={mensagemGlobal}/>
              <div className="layout-content">
                {this.props.children}
              </div>
            </div>
            <div className="layout-mask"></div>
            {this.props.loading &&
            <div className="loading-container">
              {!this.props.comunicaRenave && (
                <ProgressSpinner />
              )}
              {this.props.comunicaRenave && (
                <>
                  <ProgressSpinner />
                  <strong className="spinner-text">COMUNICANDO COM O RENAVE...</strong>
                </>
              )}
            </div>
            }
            <Message />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({ loading: state.loading, comunicaRenave: state.comunicaRenave })

export default connect(mapStateToProps)(AppContent)
