import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import React, { useState } from 'react'
import SearchInput from '../../../../components/inputs/SearchInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import { getPessoaDTO } from '../../../../dtos/cadastro/PessoaDTO'
import PessoaModal from '../../../../forms/cadastro/pessoa/PessoaModal'
import VeiculoModal from '../../../../forms/cadastro/veiculo/VeiculoModal'
import { formatCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../../../helpers/formaters'
import PessoaService from '../../../../services/cadastro/PessoaService'
import AutorizacaoTransferenciaService from '../../../../services/veiculosUsados/AutorizacaoTransferenciaService'
import EstoqueUsadosService from '../../../../services/veiculosUsados/EstoqueUsadosService'

function DadosAutorizacaoTransferenciaEstabelecimento ({ veiculo }) {
  const [estabelecimento, setEstabelecimento] = useState(null)
  const [sugestaoEstabelecimentos, setSugestaoEstabelecimentos] = useState([])
  const [codigoVei, setCodigoVei] = useState(0)
  const [visibleVeiculoModal, setVisibleVeiculoModal] = useState(false)
  const [valorVenda, setValorVenda] = useState(0)

  async function sugerirEstabelecimentos () {
    try {
      const pessoaService = new PessoaService()

      const estabelecimentos = (await pessoaService.filter(`descricaoFiltro=${estabelecimento.nomraz_pes}`)).data

      if (estabelecimentos.length === 0) {
        showWarnMessage('Não foi encontrada nenhum estabelecimento!')
      }

      setSugestaoEstabelecimentos(estabelecimentos)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um estabelecimento!')
    }
  }

  function handleChangeEstabelecimento (event) {
    setEstabelecimento({ nomraz_pes: event.target.value })
  }

  function handleOnRegisterEstabelecimento (estabelecimentoRegistrado) {
    setEstabelecimento(estabelecimentoRegistrado)
  }

  const setRetorno = (response) => {
    setState((prevState) => ({
      ...prevState,
      data: response.data,
      list: response.data,
      totalRecords: response.meta?.total,
      totalPages: response.meta?.last_page,
      quantidades: response.quantidades
    }))
  }

  const toFilter = () => {
    return stringify(state.filter, { skipNull: true, skipEmptyString: true, encode: false })
  }

  const onFilter = async () => {
    try {
      let response = await EstoqueUsadosService.filter(toFilter(), state.page, state.rows)
      setRetorno(response)
    } catch (error) {
      console.error(error.message || 'Houve um erro ao filtrar os registros!')
    }
  }

  async function enviarSolicitacao () {
    try {
      if (!estabelecimento?.codigo_pes) {
        showWarnMessage('Por favor informe um estabelecimento válido!')
        return
      }

      if (valorVenda < 0.01) {
        showWarnMessage('Por favor informe um valor de venda válido!')
        return
      }

      await AutorizacaoTransferenciaService.autorizarTransferencia(valorVenda, veiculo.placa, estabelecimento.cgccpf_pes)
      showSuccessMessage('Transferência autorizada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar a solicitação')
    }
  }

  return veiculo && (
    <div className="formgrid grid">
      <div className="field col-12">
        <h3 className="solicitacao-entrada-header mt-0">INFORMAÇÕES DO ESTABELECIMENTO</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-8 mb-2">
            <SearchInput
              //AutoComplete
              label="Estabelecimento"
              placeholder="Estabelecimento destino da transferência"
              field="nomraz_pes"
              value={estabelecimento?.nomraz_pes && estabelecimento}
              suggestions={sugestaoEstabelecimentos}
              completeMethod={sugerirEstabelecimentos}
              onChange={handleChangeEstabelecimento}
              onSelect={(e) => setEstabelecimento(e.value)}
              className="inputfield w-full"
              //RegisterModal
              edicaoHabilitada={true}
              ComponenteCadastro={PessoaModal}
              onRegister={handleOnRegisterEstabelecimento}
              primaryKeyName="codigo_pes"
              selected={estabelecimento}
              //FiltroModal
              filtroTitle="Pesquisa de estabelecimentos"
              filtersDefault={{ apenasPessoasJuridicas: true }}
              service={PessoaService}
              model={getPessoaDTO}
              columns={[
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CNPJ', format: formatCnpj }
              ]}
            />
          </div>
          <div className="field col-4 mb-2">
            <TextInput
              label="CNPJ"
              placeholder="CNPJ"
              value={formatCnpj(estabelecimento?.cgccpf_pes)}
              className="inputfield w-full"
              disabled={true}
            />
          </div>
        </div>
        <Divider/>
        <h3 className="solicitacao-entrada-header">INFORMAÇÕES DO VEÍCULO</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-8">
            <div>
              <h1>Veículo</h1>
              <div className="mt-2">
                <label>Marca/Modelo: </label><span>{veiculo.marcaModelo}</span>
              </div>
              <div className="mt-1">
                <label>Ano: </label><span>{veiculo.anoFab}/{veiculo.anoMod}</span>
              </div>
              <div className="mt-1">
                <label>Placa/Chassi: </label><span className="black underline cursor-pointer ml-1" onClick={() => {
                  if (!veiculo.codigoVeiculo) {
                    showWarnMessage('Não foi possível encontrar o veículo nos cadastros, por favor cadastre o veículo!')
                    return
                  }
                  setVisibleVeiculoModal(true)
                  setCodigoVei(veiculo.codigoVeiculo)
                }}>{veiculo.placa || veiculo.chassi}</span>
              </div>
              <div className="mt-1">
                <label>Renavam: </label><span>{veiculo.renavam}</span>
              </div>
            </div>
          </div>
          <div className="field col-2"></div>
          <div className="field col-2">
            <TextInput
              label="Valor de venda"
              placeholder="Valor de venda do veículo"
              value={formatToCurrency(valorVenda)}
              onChange={(e) => setValorVenda(formatCurrencyToDecimal(e.target.value))}
              className="inputfield w-full"
            />
          </div>
        </div>
        <div className="flex justify-content-center">
          <Button
            label="Enviar solicitação"
            className="mt-3"
            onClick={async () => enviarSolicitacao()}
          />
        </div>
      </div>

      <VeiculoModal
        visible={visibleVeiculoModal}
        hide={() => setVisibleVeiculoModal(false)}
        isVeiculoNovo={false}
        primaryKey={codigoVei}
        onRegister={async () => await onFilter()}
        edicaoHabilitada={true}
      />
    </div>
  )
}

export default DadosAutorizacaoTransferenciaEstabelecimento
