import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import React, { useState } from 'react'
import CheckBoxInput from '../../../components/inputs/CheckBoxInput'
import DateInput from '../../../components/inputs/DateInput'
import SearchInput from '../../../components/inputs/SearchInput'
import TextInput from '../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import { getPessoaDTO } from '../../../dtos/cadastro/PessoaDTO'
import { getVeiculoDTO } from '../../../dtos/cadastro/VeiculoDTO'
import PessoaModal from '../../../forms/cadastro/pessoa/PessoaModal'
import VeiculoModal from '../../../forms/cadastro/veiculo/VeiculoModal'
import { formatCnpj, formatCpf, formatCpfOrCnpj, formatCurrencyToDecimal, formatPlaca, formatToCurrency } from '../../../helpers/formaters'
import PessoaService from '../../../services/cadastro/PessoaService'
import VeiculoService from '../../../services/cadastro/VeiculoService'
import SolicitacaoEntradaService from '../../../services/veiculosUsados/SolicitacaoEntradaService'
import './_solicitacaoEntrada.scss'

function SolicitacaoDeEntrada ({ tipoEntrada, setActiveIndex, veiculo, setVeiculo }) {
  const [checkVeiculoNomeLoja, setCheckVeiculoNomeLoja] = useState(isEntradaVeiculoProprio())

  const [sugestaoVeiculos, setSugestaoVeiculos] = useState([])

  const [proprietario, setProprietario] = useState(null)
  const [sugestaoProprietarios, setSugestaoProprietarios] = useState([])

  const [data, setData] = useState(new Date())
  const [valorDaCompra, setValorDaCompra] = useState(0)

  function isCompra () {
    return tipoEntrada === 'compra'
  }

  function isEntradaVeiculoProprio () {
    return tipoEntrada === 'veiculo-proprio'
  }

  const sugerirVeiculos = async () => {
    try {
      const veiculoService = new VeiculoService()

      const veiculos = (await veiculoService.filter(`apenasVeiculosUsados&descricaoFiltro=${veiculo.placa_vei}`)).data

      if (veiculos.length === 0) {
        showWarnMessage('Não foi encontrado nenhum veículo usado!')
      }

      setSugestaoVeiculos(veiculos)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um veículo usado!')
    }
  }

  function handleChangeVeiculo (event) {
    setVeiculo({ placa_vei: event.target.value })
  }

  function handleOnRegisterVeiculo (veiculoRegistrado) {
    veiculoRegistrado.dahmed_vei = new Date(veiculoRegistrado.dahmed_vei)
    setVeiculo(veiculoRegistrado)
  }

  const sugerirProprietarios = async () => {
    try {
      const pessoaService = new PessoaService()

      const proprietarios = (await pessoaService.filter(`descricaoFiltro=${proprietario.nomraz_pes}`)).data

      if (proprietarios.length === 0) {
        showWarnMessage('Não foi encontrada nenhuma pessoa!')
      }

      setSugestaoProprietarios(proprietarios)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  function handleChangeProprietario (event) {
    setProprietario({ nomraz_pes: event.target.value })
  }

  function handleOnRegisterProprietario (proprietarioRegistrado) {
    setProprietario(proprietarioRegistrado)
  }

  async function enviarSolicitacao () {
    try {
      if (!veiculo?.codigo_vei) {
        showWarnMessage('Por favor informe um veículo válido!')
        return
      }

      if (!data) {
        showWarnMessage('Por favor informe uma data válida!')
        return
      }

      if (isCompra()) {
        if (!proprietario?.codigo_pes) {
          showWarnMessage('Por favor informe um proprietário válido!')
          return
        }

        if (valorDaCompra < 0.01) {
          showWarnMessage('Por favor informe um valor de compra válido!')
          return
        }

        await SolicitacaoEntradaService.solicitacaoEntradaEstoque(data, valorDaCompra, veiculo, proprietario)
        setActiveIndex(1)
      }

      if (isEntradaVeiculoProprio()) {
        await SolicitacaoEntradaService.solicitacaoEntradaEstoqueVeiculoProprio(data, veiculo)
      }

      showSuccessMessage('Solicitação de entrada enviada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar a solicitação')
    }
  }

  return (
    <div className="formgrid grid">
      <div className="field col-3"></div>
      <div className="field col-6">
        <h3 className="solicitacao-entrada-header">INFORMAÇÕES DO VEÍCULO</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-7">
            <SearchInput
              //AutoComplete
              label="Veículo"
              placeholder="Veículo a ser comprado"
              field="placa_vei"
              value={veiculo?.placa_vei && veiculo}
              suggestions={sugestaoVeiculos}
              completeMethod={sugerirVeiculos}
              onChange={handleChangeVeiculo}
              onSelect={(e) => {
                setVeiculo(e.value)
              }}
              className="inputfield w-full"
              //RegisterModal
              edicaoHabilitada={true}
              ComponenteCadastro={VeiculoModal}
              onRegister={handleOnRegisterVeiculo}
              primaryKeyName="codigo_vei"
              selected={veiculo}
              isVeiculoNovo={false}
              //FiltroModal
              filtroTitle="Pesquisa de veículos usados"
              filtersDefault={{ apenasVeiculosUsados: true }}
              service={VeiculoService}
              model={getVeiculoDTO}
              columns={[
                { campo: 'placa_vei', nome: 'Placa', format: formatPlaca },
                { campo: 'descri_vei', nome: 'Descrição' }
              ]}
            />
          </div>
          <div className="field col-5 flex align-items-center justify-content-center">
            <CheckBoxInput
              label="Veículo em nome da loja"
              value={checkVeiculoNomeLoja}
              checked={checkVeiculoNomeLoja}
              onChange={() => setCheckVeiculoNomeLoja(!checkVeiculoNomeLoja)}
              disabled={isEntradaVeiculoProprio()}
            />
          </div>
        </div>
        {isCompra() && (
          <>
            <Divider/>
            <h3 className="solicitacao-entrada-header">INFORMAÇÕES DO PROPRIETÁRIO</h3>
            <Divider/>
            <div className="formgrid grid">
              <div className="field col-7">
                <SearchInput
                  //AutoComplete
                  label="Proprietário"
                  placeholder="Proprietário do veículo"
                  field="nomraz_pes"
                  value={proprietario?.nomraz_pes && proprietario}
                  suggestions={sugestaoProprietarios}
                  completeMethod={sugerirProprietarios}
                  onChange={handleChangeProprietario}
                  onSelect={(e) => setProprietario(e.value)}
                  className="inputfield w-full"
                  //RegisterModal
                  edicaoHabilitada={true}
                  ComponenteCadastro={PessoaModal}
                  onRegister={handleOnRegisterProprietario}
                  primaryKeyName="codigo_pes"
                  selected={proprietario}
                  //FiltroModal
                  filtroTitle="Pesquisa de pessoas"
                  service={PessoaService}
                  model={getPessoaDTO}
                  columns={[
                    { campo: 'nomraz_pes', nome: 'Nome' },
                    { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatCpfOrCnpj }
                  ]}
                />
              </div>
              <div className="field col-5">
                <TextInput
                  label="CPF/CNPJ"
                  placeholder="CPF/CNPJ"
                  value={proprietario?.cgccpf_pes && proprietario.cgccpf_pes.length > 11 ? formatCnpj(proprietario?.cgccpf_pes) : formatCpf(proprietario?.cgccpf_pes)}
                  className="inputfield w-full"
                  disabled={true}
                />
              </div>
            </div>
          </>
        )}
        <Divider/>
        <h3 className="solicitacao-entrada-header">ESTOQUE</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-7">
            <DateInput
              label={`Data ${isCompra() ? 'da compra' : 'da entrada no estoque'}`}
              placeholder="Data"
              value={data}
              onChange={(e) => setData(e.value)}
              className="inputfield w-full"
            />
          </div>
          {isCompra() && (
            <div className="field col-5">
              <TextInput
                label="Valor da compra"
                placeholder="Valor da compra do veículo"
                value={formatToCurrency(valorDaCompra)}
                onChange={(e) => setValorDaCompra(formatCurrencyToDecimal(e.target.value))}
                className="inputfield w-full"
              />
            </div>
          )}
        </div>
        <div className="flex justify-content-center">
          <Button
            label="Enviar solicitação"
            className="mt-3"
            onClick={async () => enviarSolicitacao()}
          />
        </div>
      </div>
      <div className="field col-3"></div>
    </div>
  )
}

export default SolicitacaoDeEntrada
